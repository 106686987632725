var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "spend" }, [
    _c("div", { staticClass: "close" }, [
      _c(
        "div",
        {
          staticClass: "close_button",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.close()
            },
          },
        },
        [_vm._v("x")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "spend_content" },
      [
        _c("div", [
          _vm._v(
            "当前使用的手机号为****" + _vm._s(_vm.phoneId) + ",并不是会员"
          ),
        ]),
        _c(
          "van-button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.buttonFlag,
                expression: "buttonFlag",
              },
            ],
            staticClass: "spend_IOS",
            attrs: { type: "warning", round: "" },
          },
          [_vm._v("请打开电脑端输入网址tool.farbun.com进行充值")]
        ),
        _c(
          "van-button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.buttonFlag,
                expression: "!buttonFlag",
              },
            ],
            staticClass: "spend_button",
            attrs: { type: "warning", round: "" },
            on: {
              click: function ($event) {
                return _vm.account()
              },
            },
          },
          [_vm._v("成为会员")]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "spend_footer",
        on: {
          click: function ($event) {
            return _vm.PhoneDian(_vm.servicePhone)
          },
        },
      },
      [
        _c("span", [_vm._v("联系客服:")]),
        _c("span", [_vm._v("客服 :" + _vm._s(_vm.servicePhone))]),
        _c("van-icon", { staticClass: "phone_icon", attrs: { name: "phone" } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }